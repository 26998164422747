<template>
  <div class="column is-3-widescreen is-4-desktop is-4-tablet is-6-mobile">
    <card-widget
      v-if="value && value.obsDate"
      :icon="icon"
      :customIcon="customIcon"
      :number="value.value"
      :useBoolValue="useBoolValue"
      :boolValue="boolValue"
      :number2="number2"
      :showNum2="showNum2"
      :suffix="suffix"
      :prefix="prefix"
      :lookup="lookup"
      :hideNum="hideNum"
      :label="label"
      :subFooter="subFooter"
      :footer1="formatDateTimeRecent(value.obsDate)"
      :footer2="value.by"
      :loading="loading"
      class="clickable"
      :showAdd="canAddNew"
      :showGraph="true"
      :reducedSize="reducedSize"
      @add="$emit('add')"
      @click.native="editOb"
      @graph="$emit('graph')"
    />
    <card-widget
      v-else
      :icon="icon"
      :customIcon="customIcon"
      :suffix="none"
      :label="label"
      :hideNum="true"
      :loading="loading"
      :class="{ clickable: canAddNew }"
      @add="$emit('add')"
      @click.native="editOb"
      :showAdd="canAddNew"
    />
    <div v-if="anydata" class="card chartCard" :class="{ clickable: !!chartData }" @click.stop="$emit('graph')">
      <line-chart :chart-data="chartData" :options="chartOptions" :styles="myStyles" />
      <b-loading :is-full-page="false" v-model="isLoadingChartData" />
    </div>
  </div>
</template>

<script>
import CardWidget from "@/components/CardWidget";
import DatesMixin from "@/mixins/datesMixin";
import PermissionsEnum from "@/enums/permissions";
import * as chartConfig from "@/components/Charts/chart.config";
import LineChart from "@/components/Charts/LineChart";

export default {
  mixins: [DatesMixin],
  components: {
    CardWidget,
    LineChart
  },
  props: {
    value: { type: Object, default: () => ({ value: null, obsDate: null, by: null }) },
    icon: { type: String, default: null },
    customIcon: { type: String, default: null },
    number: { type: Number, default: 0 },
    hideNum: { type: Boolean, default: false },
    number2: { type: Number, default: 0 },
    showNum2: { type: Boolean, default: false },
    prefix: { type: String, default: null },
    suffix: { type: String, default: null },
    lookup: { type: Array, default: null },
    label: { type: String, default: null },
    type: { type: String, default: "is-primary" },
    footer1: { type: String, default: null },
    footer2: { type: String, default: null },
    subFooter: { type: String, default: null },
    loading: { type: Boolean, default: false },
    isLoadingChartData: { type: Boolean, default: false },
    chartData: { type: Object, default: null },
    addPermission: { type: Number, default: PermissionsEnum.ObservationAdd },
    useBoolValue: { type: Boolean, default: false },
    boolValue: { type: Boolean },
    reducedSize: { type: Boolean }
  },
  data() {
    return {
      none: "<none>",
      chartOptions: chartConfig.chartOptionsSmall(),
      myStyles: { height: "130px", position: "relative" }
    };
  },
  computed: {
    canAddNew() {
      return this.$store.getters.hasPermission(this.addPermission);
    },
    anydata() {
      return this.chartData?.datasets[0]?.data?.length;
    }
  },
  methods: {
    editOb() {
      // edit when no existing, will trigger new, but only if we are allowed
      if (this.value?.observationId || this.canAddNew) this.$emit("edit");
    }
  }
};
</script>

<style scoped>
.chartCard {
  margin-top: -1.75rem;
}
</style>
