import * as chartConfig from "@/components/Charts/chart.config";
import PupilReaction from "@/enums/pupilReaction.js";
import BowelEnum from "@/enums/bowelEnum";
import ObsType from "@/enums/observationType.js";

export default {
  data() {
    return {
      obsName: ObsType.Lookup[this.obsType],
      customChartData: {
        blood: chartData => {
          return {
            labels: chartData.map(a => a.obsDate),
            datasets: [
              {
                ...chartConfig.defaultDataSetOptions,
                data: chartData.map(a => a.value),
                label: "Systolic"
              },
              {
                ...chartConfig.defaultDataSetOptions,
                data: chartData.map(a => a.diastolic),
                label: "Diastolic",
                borderColor: chartConfig.chartColors.default.info,
                pointBackgroundColor: chartConfig.chartColors.default.info,
                pointHoverBackgroundColor: chartConfig.chartColors.default.info
              }
            ]
          };
        },
        pupils: chartData => {
          return {
            datasets: [
              {
                ...chartConfig.defaultDataSetOptions,
                data: chartData.map(a => ({ x: a.obsDate, y: a.value, reaction: a.leftReaction })),
                label: "Left Eye"
              },
              {
                ...chartConfig.defaultDataSetOptions,
                data: chartData.map(a => ({ x: a.obsDate, y: a.rightSize, reaction: a.rightReaction })),
                label: "Right Eye",
                borderColor: chartConfig.chartColors.default.complianceFull,
                pointBackgroundColor: chartConfig.chartColors.default.complianceFull,
                pointHoverBackgroundColor: chartConfig.chartColors.default.complianceFull
              }
            ]
          };
        },
        bowel: chartData => {
          return {
            datasets: [
              {
                ...chartConfig.defaultDataSetOptions,
                data: chartData.map(a => ({ x: a.obsDate, y: a.value, stoolType: a.stoolType })),
                label: "Bowel Movement"
              }
            ]
          };
        },
        limbs: chartData => {
          return {
            datasets: [
              {
                ...chartConfig.defaultDataSetOptions,
                data: chartData.map(a => ({ x: a.obsDate, y: a.value })),
                label: "Left Arm"
              },
              {
                ...chartConfig.defaultDataSetOptions,
                data: chartData.map(a => ({ x: a.obsDate, y: a.armRight })),
                label: "Right Arm",
                borderColor: chartConfig.chartColors.default.complianceFull,
                pointBackgroundColor: chartConfig.chartColors.default.complianceFull,
                pointHoverBackgroundColor: chartConfig.chartColors.default.complianceFull
              },
              {
                ...chartConfig.defaultDataSetOptions,
                data: chartData.map(a => ({ x: a.obsDate, y: a.legLeft })),
                label: "Left Leg",
                borderColor: chartConfig.chartColors.default.compliancePartial,
                pointBackgroundColor: chartConfig.chartColors.default.compliancePartial,
                pointHoverBackgroundColor: chartConfig.chartColors.default.compliancePartial
              },
              {
                ...chartConfig.defaultDataSetOptions,
                data: chartData.map(a => ({ x: a.obsDate, y: a.legRight })),
                label: "Right Leg",
                borderColor: chartConfig.chartColors.default.other1,
                pointBackgroundColor: chartConfig.chartColors.default.other1,
                pointHoverBackgroundColor: chartConfig.chartColors.default.other1
              }
            ]
          };
        }
      },
      customChartOptions: {
        pupils: chartConfig.chartOptionsUnitsTimeScale(
          "Pupil Scale",
          "mm",
          null,
          data => `${data.y} mm (${PupilReaction.Lookup[data.reaction]})`,
          this.fromDate,
          this.toDate
        ),
        bowel: chartConfig.chartOptionsUnitsTimeScale(
          "Quantity",
          null,
          BowelEnum.QuantityLookup,
          data => `${BowelEnum.QuantityLookup[data.y]} (${BowelEnum.LookupShort[data.stoolType]})`,
          this.fromDate,
          this.toDate
        )
      }
    };
  },

  methods: {
    getDefaultOptions() {
      switch (this.obsType) {
        case ObsType.Enum.Pupils:
          return this.customChartOptions.pupils;
        case ObsType.Enum.Bowel:
          return this.customChartOptions.bowel;
        default:
          return chartConfig.chartOptionsUnitsTimeScale(this.obsName, this.unit, this.lookup, null, this.fromDate, this.toDate);
      }
    }
  }
};
