<template>
  <ObsDialog
    :customIcon="CustomIcons.obsComaScaleInvert"
    url="obs_comascale/"
    obName="Coma Scale"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
    fieldClass="label-medium"
  >
    <b-field label="Eyes Open" horizontal class="label-medium">
      <EnumDropDown v-model.number="observation.eyes" :data="comaEnum.EyesSelectLookup" required :disabled="readonly" v-focus />
    </b-field>
    <b-field label="Verbal Response" horizontal class="label-medium">
      <EnumDropDown v-model.number="observation.verbal" :data="comaEnum.VerbalSelectLookup" required :disabled="readonly" />
    </b-field>
    <b-field label="Motor Resposne" horizontal class="label-medium">
      <EnumDropDown v-model.number="observation.motor" :data="comaEnum.MotorSelectLookup" required :disabled="readonly" />
    </b-field>
    <b-field label="Coma Scale" horizontal class="label-medium">
      {{ observation.value }} - {{ comaEnum.ScoreLookup[observation.rating] }}
    </b-field>
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import obsMixin from "@/mixins/obsMixin.js";
import EnumDropDown from "@/components/EnumDropDown";
import ComaEnum from "@/enums/comaScale.js";

export default {
  mixins: [obsMixin],
  components: { ObsDialog, EnumDropDown },
  data() {
    return {
      comaEnum: ComaEnum
    };
  },
  computed: {
    gcsScore() {
      return (this.observation.eyes ?? 0) + (this.observation.verbal ?? 0) + (this.observation.motor ?? 0);
    },
    gcsRating() {
      if (this.gcsScore < 9) return ComaEnum.ScoreRating.Severe;
      if (this.gcsScore <= 12) return ComaEnum.ScoreRating.Moderate;
      return ComaEnum.ScoreRating.Mild;
    }
  },
  watch: {
    gcsScore: {
      immediate: true,
      handler(v) {
        this.observation.value = v;
      }
    },
    gcsRating: {
      immediate: true,
      handler(v) {
        this.observation.rating = v;
      }
    }
  }
};
</script>
