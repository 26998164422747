<template>
  <ObsWidget
    :value="value"
    :icon="Icons.obsLimbs"
    :lookup="lookup"
    label="Limb Movement"
    :number2="legLeft"
    :showNum2="true"
    :loading="loading"
    @edit="$emit('edit')"
    @add="$emit('add')"
    @graph="$emit('graph')"
    :chartData="chartData"
    :loadingChart="isLoadingChartData"
    subFooter="Arms / Legs"
    :reducedSize="true"
  />
</template>

<script>
import ObsWidget from "@/components/observations/ObsWidget";
import IconsMixin from "@/mixins/iconsMixin.js";
import LimbMovement from "@/enums/limbMovement.js";

export default {
  mixins: [IconsMixin],
  components: { ObsWidget },
  props: ["value", "loading", "chartData", "isLoadingChartData"],
  data() {
    return {
      lookup: LimbMovement.LookupShort
    };
  },
  computed: {
    legLeft() {
      return this.value?.legLeft ?? null;
    }
  }
};
</script>
