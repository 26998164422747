<template>
  <ObsWidget
    :value="value"
    :lookup="lookup"
    :icon="Icons.obsBowel"
    label="Bowel"
    :loading="loading"
    @edit="$emit('edit')"
    @add="$emit('add')"
    @graph="$emit('graph')"
    :chartData="chartData"
    :loadingChart="isLoadingChartData"
  />
</template>

<script>
import ObsWidget from "@/components/observations/ObsWidget";
import IconsMixin from "@/mixins/iconsMixin.js";
import BowelEnum from "@/enums/bowelEnum";

export default {
  mixins: [IconsMixin],
  components: { ObsWidget },
  props: ["value", "loading", "chartData", "isLoadingChartData"],
  data() {
    return {
      lookup: BowelEnum.QuantityLookup
    };
  }
};
</script>
