<template>
  <ObsWidget
    :value="value"
    :customIcon="CustomIcons.obsBlood"
    :number2="number2"
    :showNum2="true"
    label="Blood Pressure"
    :loading="loading"
    @edit="$emit('edit')"
    @add="$emit('add')"
    @graph="$emit('graph')"
    :chartData="chartData"
    :loadingChart="isLoadingChartData"
  />
</template>

<script>
import ObsWidget from "@/components/observations/ObsWidget";
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  mixins: [IconsMixin],
  components: { ObsWidget },
  props: ["value", "loading", "number2", "chartData", "isLoadingChartData"]
};
</script>
