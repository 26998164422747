<template>
  <ObsDialog
    :icon="Icons.obsBowel"
    url="obs_bowel/"
    obName="Bowel Movement"
    :lookup="bowelEnum.QuantityLookup"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    :customChartData="customChartData.bowel"
    :customChartOptions="customChartOptions.bowel"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
  >
    <b-field label="Quantity" horizontal>
      <EnumDropDown v-model="observation.value" :data="bowelEnum.QuantitySelect" required :disabled="readonly" v-focus />
    </b-field>
    <ValidationProvider rules="required" v-slot="{ errors }">
      <b-field label="Stool Form" horizontal v-if="observation.value" :type="{ 'is-danger': errors[0] }" :message="errors">
        <b-dropdown v-model="observation.stoolType" required :disabled="readonly">
          <template #trigger="{ active }" v-if="observation.stoolType">
            <div class="media">
              <img class="media-left selectedImage" :src="require('@/assets/stools/type' + observation.stoolType + '.png')" />
              <div class="media-content">
                {{ bowelEnum.Lookup[observation.stoolType] }}
                <b-icon class="" :icon="active ? 'menu-up' : 'menu-down'" />
              </div>
            </div>
          </template>
          <template #trigger="{ active }" v-else>
            <b-button label="<Select Type>" :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>
          <b-dropdown-item v-for="item in bowelEnum.SelectLookup" :key="item.id" :value="item.id">
            <div class="media">
              <img class="media-left dropdownImage" :src="require(`@/assets/stools/type${item.id}.png`)" />
              <div class="media-content">
                <h3>{{ item.value }}</h3>
                <small>{{ bowelEnum.Desc[item.id] }}</small>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </b-field>
    </ValidationProvider>
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import obsMixin from "@/mixins/obsMixin.js";
import obsDataMixin from "@/mixins/obsDataMixin.js";
import EnumDropDown from "@/components/EnumDropDown";
import BowelEnum from "@/enums/bowelEnum";

export default {
  mixins: [obsMixin, obsDataMixin],
  components: { ObsDialog, EnumDropDown },
  data() {
    return {
      bowelEnum: BowelEnum
    };
  }
};
</script>

<style scoped>
.selectedImage {
  max-height: 30px;
}
.dropdownImage {
  max-width: 130px;
}
</style>
