<template>
  <ObsDialog
    :icon="Icons.obsOxygen"
    url="obs_oxygen/"
    obName="Oxygen"
    obNames="Oxygen"
    unit="%"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
    fieldClass="label-medium"
  >
    <b-field label="Oxygen Saturation" horizontal class="label-medium">
      <div class="levelLeftMobile">
        <b-numberinput
          expanded
          v-model="observation.value"
          :controls="false"
          required="!readonly"
          :disabled="readonly"
          v-focus
          :min="1"
          :max="100"
        />
        <p class="control unitLabel">%</p>
      </div>
    </b-field>
    <b-field label="Delivery" horizontal class="label-medium">
      <EnumDropDown v-model="observation.delivery" :data="delivery.SelectLookup" required :disabled="readonly" />
    </b-field>
    <b-field v-if="observation.delivery !== delivery.Enum.RoomAir" label="Oxygen Prescription" horizontal class="label-medium">
      <div class="levelLeftMobile">
        <b-numberinput
          expanded
          v-model="observation.prescription"
          :controls="false"
          required="!readonly"
          :disabled="readonly"
          :min="0.01"
          :max="10"
          :step="0.01"
        />
        <p class="control unitLabel">L</p>
      </div>
    </b-field>
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import obsMixin from "@/mixins/obsMixin.js";
import EnumDropDown from "@/components/EnumDropDown";
import OxygenDeliveryEnum from "@/enums/oxygenDelivery";

export default {
  mixins: [obsMixin],
  components: { ObsDialog, EnumDropDown },
  data() {
    return {
      delivery: OxygenDeliveryEnum
    };
  }
};
</script>
