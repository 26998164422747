<template>
  <div>
    <b-radio v-model="newValue" :name="name" :native-value="reactEnum.Reacts" required :disabled="disabled">Reacts</b-radio>
    <b-radio v-model="newValue" :name="name" :native-value="reactEnum.NoReaction" :disabled="disabled">No Reaction</b-radio>
    <b-radio v-model="newValue" :name="name" :native-value="reactEnum.Closed" :disabled="disabled">Eye Closed</b-radio>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Number, default: null },
    name: { type: String, required: true },
    disabled: { type: Boolean, required: false }
  },
  data() {
    return {
      newValue: null,
      reactEnum: {
        Reacts: 1,
        NoReaction: 2,
        Closed: 3
      }
    };
  },
  watch: {
    value(newValue) {
      this.newValue = newValue;
    },
    newValue(newValue) {
      this.$emit("input", newValue);
    }
  },
  created() {
    this.newValue = this.value;
  }
};
</script>
