import { GetSelectLookup } from "./common";

const FilterEnum = {
  Last12Hours: 0,
  Last24Hours: 1,
  Last7Days: 2,
  Last30Days: 3,
  Recent: 4,
  Custom: 5
};

const Lookup = ["Last 12 Hours", "Last 24 Hours", "Last 7 Days", "Last 30 Days", "Most Recent", "Custom Date Range"];

export default {
  Enum: FilterEnum,
  SelectLookup: GetSelectLookup(FilterEnum, Lookup)
};
