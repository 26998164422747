<template>
  <ObsWidget
    :value="value"
    :customIcon="painCustomIcon"
    label="Pain Score"
    :loading="loading"
    @edit="$emit('edit')"
    @add="$emit('add')"
    @graph="$emit('graph')"
  />
</template>

<script>
import ObsWidget from "@/components/observations/ObsWidget";
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  mixins: [IconsMixin],
  components: { ObsWidget },
  props: ["value", "loading"],
  computed: {
    painCustomIcon() {
      switch (this.value?.value) {
        // custom icon not consistant with other icons, so not shown for now
        // case 10:
        //   return "pain_10.png";
        // case 9:
        // case 8:
        // case 7:
        //   return "pain_8.png";
        // case 6:
        //   return "pain_6.png";
        // case 5:
        // case 4:
        //   return "pain_4.png";
        // case 3:
        // case 2:
        // case 1:
        //   return "pain_2.png";
        // case 0:
        //   return "pain_0.png";
        default:
          return this.CustomIcons.obsPain;
      }
    }
  }
};
</script>
