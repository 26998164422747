<template>
  <ObsWidget
    :value="value"
    :icon="Icons.obsPupils"
    label="Pupils"
    suffix="mm"
    :number2="pupilRightSize"
    :showNum2="true"
    :loading="loading"
    @edit="$emit('edit')"
    @add="$emit('add')"
    @graph="$emit('graph')"
    :chartData="chartData"
    :loadingChart="isLoadingChartData"
  />
</template>

<script>
import ObsWidget from "@/components/observations/ObsWidget";
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  mixins: [IconsMixin],
  components: { ObsWidget },
  props: ["value", "loading", "chartData", "isLoadingChartData"],
  computed: {
    pupilRightSize() {
      return this.value?.rightSize ?? null;
    }
  }
};
</script>
