import { GetSelectLookup } from "./common";

const OxygenDeliveryEnum = {
  RoomAir: 0,
  FaceMask: 1,
  NasalProngs: 2,
  Oxygenator: 3
};

const OxygenDeliveryLookup = ["Room Air", "Face Mask", "Nasal Prongs", "Oxygenator"];

export default {
  Enum: OxygenDeliveryEnum,
  SelectLookup: GetSelectLookup(OxygenDeliveryEnum, OxygenDeliveryLookup),
  Lookup: OxygenDeliveryLookup
};
