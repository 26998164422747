<template>
  <div :class="{ 'column is-4': !stacked, hidePreviousXAxis: hidePreviousXAxis }">
    <div class="card chartCard columns is-mobile " :class="{ clickable: !!chartData }" @click.stop="$emit('graph')">
      <div class="column is-1 hero-body">
        <b-icon v-if="icon" :icon="icon" size="is-large" type="is-primary" />
        <span v-if="customIcon" class="icon has-text-primary is-large">
          <img :src="'/img/icons/' + customIcon" />
        </span>
      </div>
      <div v-if="anydata" class="column">
        <line-chart :chart-data="chartData" :options="obsTypeOptions" :styles="myStyles" />
        <b-loading :is-full-page="false" v-model="isLoadingChartData" />
      </div>
      <div v-else class="card hero-body">
        <div class="card-content">No {{ obsName }} data found for that time period</div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/Charts/LineChart";
import obsDataMixin from "@/mixins/obsDataMixin.js";

export default {
  mixins: [obsDataMixin],
  components: {
    LineChart
  },
  props: {
    isLoadingChartData: { type: Boolean, default: false },
    chartData: { type: Object, default: null },
    obsType: { type: Number, required: true },
    unit: { type: String },
    lookup: { type: Array },
    fromDate: { type: Date },
    toDate: { type: Date },
    stacked: { type: Boolean },
    icon: { type: String },
    customIcon: { type: String },
    hidePreviousXAxis: { type: Boolean }
  },
  data() {
    return {
      myStyles: { height: this.stacked ? "150px" : "300px" }
    };
  },
  computed: {
    anydata() {
      return this.chartData?.datasets[0]?.data?.length;
    },
    obsTypeOptions() {
      var options = this.getDefaultOptions();
      // options.legend.position = "right";

      // For stacked chart, we ensure y axis scale to same fixed width
      if (this.stacked) {
        options.legend.display = false;
        options.scales.yAxes[0].afterFit = function(scaleInstance) {
          scaleInstance.width = 80;
        };
        options.scales.yAxes[0].ticks.precision = 0;

        // Hide X axis on resulted in different values, so using class hidePreviousXAxis
        // if (!this.showStackedXAxis) options.scales.xAxes[0].ticks = { display: false };

        // options.title = {
        //   display: true,
        //   text: "Custom Chart Title",
        //   position: "right"
        // };
      }

      return options;
    }
  }
};
</script>

<style scoped>
.noGraph {
  min-height: 200px;
}
.hidePreviousXAxis {
  margin-top: -38px;
}
</style>
