<template>
  <ObsDialog
    :customIcon="CustomIcons.obsRespirationInvert"
    url="obs_respiration/"
    obName="Respiration"
    obNames="Respiration"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
    fieldClass="label-medium"
  >
    <b-field label="Respiratory Rate" horizontal class="label-medium">
      <div class="levelLeftMobile">
        <b-numberinput
          expanded
          v-model="observation.value"
          :controls="false"
          required="!readonly"
          :disabled="readonly"
          v-focus
          min="0"
          max="40"
        />
        <p class="control unitLabel"></p>
      </div>
    </b-field>
    <b-field label="Labored Breathing" horizontal class="label-medium">
      <b-checkbox v-model="observation.labored" type="is-primary" />
    </b-field>
    <b-field label="Rapid Breathing" horizontal class="label-medium">
      <b-checkbox v-model="observation.rapid" type="is-primary" />
    </b-field>
    <b-field label="Headache" horizontal class="label-medium">
      <b-checkbox v-model="observation.headache" type="is-primary" />
    </b-field>
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import obsMixin from "@/mixins/obsMixin.js";

export default {
  mixins: [obsMixin],
  components: { ObsDialog }
};
</script>
