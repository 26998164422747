<template>
  <div class="slider_container">
    <img src="@/assets/pain_scale.png" alt="Pain Scale" :usemap="'#' + mapRef" />

    <map :name="mapRef" :class="{ clickable: !readonly }">
      <area coords="284,12,29,798" shape="rect" @click.prevent="setValue(0)" />
      <area coords="286,18,483,794" shape="rect" @click.prevent="setValue(1)" />
      <area coords="647,24,485,800" shape="rect" @click.prevent="setValue(2)" />
      <area coords="823,798,646,26" shape="rect" @click.prevent="setValue(3)" />
      <area coords="825,24,1017,798" shape="rect" @click.prevent="setValue(4)" />
      <area coords="1019,22,1221,798" shape="rect" @click.prevent="setValue(5)" />
      <area coords="1223,30,1417,800" shape="rect" @click.prevent="setValue(6)" />
      <area coords="1420,802,1587,28" shape="rect" @click.prevent="setValue(7)" />
      <area coords="1589,22,1796,798" shape="rect" @click.prevent="setValue(8)" />
      <area coords="1798,24,1974,800" shape="rect" @click.prevent="setValue(9)" />
      <area coords="1976,20,2209,800" shape="rect" @click.prevent="setValue(10)" />
    </map>

    <b-slider class="slider" size="is-medium" :value="value" @input="setValue" :disabled="readonly" :min="0" :max="10" rounded />
  </div>
</template>

<script>
import imageMapResize from "image-map-resizer";

export default {
  props: {
    mapRef: { type: String, required: true },
    value: { type: Number, required: true },
    readonly: { type: Boolean, default: false }
  },
  methods: {
    setValue(v) {
      if (!this.readonly) this.$emit("input", v);
    }
  },
  mounted() {
    imageMapResize();
  }
};
</script>

<style>
.slider_container {
  position: relative;
}
.slider {
  position: absolute;
  left: 6%;
  top: 8%;
  width: 86%;
}
.slider .b-slider-track {
  background: inherit !important;
}

@media screen and (max-width: 1340px) {
  .slider {
    top: 3%;
  }
}
@media screen and (max-width: 1250px) {
  .slider {
    top: 8%;
  }
}
@media screen and (max-width: 992px) {
  .slider {
    top: 4%;
  }
}
@media screen and (max-width: 896px) {
  .slider {
    top: 1%;
  }
}
@media screen and (max-width: 768px) {
  .slider {
    top: 8%;
  }
}
@media screen and (max-width: 588px) {
  .slider {
    top: 6%;
  }
}
</style>
