<template>
  <ObsDialog
    :icon="Icons.obsConscious"
    url="obs_conscious/"
    obName="Consciousness"
    obNames="Consciousness"
    :lookup="consciousLevel.LookupShort"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
  >
    <b-field label="Level of Consciousness" horizontal>
      <EnumDropDown v-model="observation.value" :data="consciousLevel.SelectLookup" required :disabled="readonly" v-focus />
    </b-field>
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import obsMixin from "@/mixins/obsMixin.js";
import EnumDropDown from "@/components/EnumDropDown";
import ConsciousLevelEnum from "@/enums/consciousLevel";

export default {
  mixins: [obsMixin],
  components: { ObsDialog, EnumDropDown },
  data() {
    return {
      consciousLevel: ConsciousLevelEnum
    };
  }
};
</script>
