<template>
  <div class="box">
    <div class="columns">
      <div class="column">
        <b-field label="Date Range" horizontal>
          <EnumDropDown :value="value" @input="v => $emit('input', v)" :data="selectLookup" />
          <b-datepicker
            v-if="showDatePicker"
            placeholder="Click to select..."
            :value="searchDates"
            @input="$emit('newDates', v)"
            range
            ref="datepicker"
          />
        </b-field>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import EnumDropDown from "@/components/EnumDropDown";
import FilterType from "@/enums/dateFilter.js";
import dates from "@/common/dates";

export default {
  components: { EnumDropDown },
  props: {
    value: { type: Number },
    searchDates: { type: Array }
  },
  data() {
    return {
      selectLookup: FilterType.SelectLookup
    };
  },
  computed: {
    showDatePicker() {
      return this.filterType === FilterType.Enum.Custom;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        const now = dates.now();
        let newDates = null;
        switch (v) {
          case FilterType.Enum.Last12Hours:
            newDates = [dates.addHours(now, -12), now];
            break;
          case FilterType.Enum.Last24Hours:
            newDates = [dates.addDays(now, -1), now];
            break;
          case FilterType.Enum.Last7Days:
            newDates = [dates.addDays(now, -7), now];
            break;
          case FilterType.Enum.Last30Days:
            newDates = [dates.addDays(now, -30), now];
            break;
          case FilterType.Enum.Recent:
            newDates = [null, null];
            break;
          case FilterType.Enum.Custom:
            if (this.searchDates[0] === null) {
              // if not set, default to last 24
              newDates = [dates.addDays(now, -1), now];
            }
            this.$nextTick(() => this.$refs.datepicker.toggle());
            break;
        }
        this.$emit("newDates", newDates);
      }
    }
  }
};
</script>
