<template>
  <ObsDialog
    :icon="Icons.obsLimbs"
    url="obs_limbs/"
    obName="Limb Movement"
    :lookup="lookup"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    :customChartData="customChartData.limbs"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
  >
    <div class="columns">
      <div class="column">
        <b-field label="Left Arm" horizontal class="label-long">
          <EnumDropDown v-model="observation.value" :data="armSelect" required :disabled="readonly" v-focus />
        </b-field>
        <b-field label="Left Leg" horizontal class="label-long">
          <EnumDropDown v-model="observation.legLeft" :data="legSelect" required :disabled="readonly" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Right Arm" horizontal class="label-long">
          <EnumDropDown v-model="observation.armRight" :data="armSelect" required :disabled="readonly" />
        </b-field>
        <b-field label="Right Leg" horizontal class="label-long">
          <EnumDropDown v-model="observation.legRight" :data="legSelect" required :disabled="readonly" />
        </b-field>
      </div>
    </div>

    <template v-slot:tableColumns>
      <b-table-column label="Date" field="obsDate" v-slot="props">
        {{ formatDateTimeRecent(props.row.obsDate) }}
      </b-table-column>
      <b-table-column label="Arms" field="value" v-slot="props">
        {{ lookup[props.row.value] }} /{{ lookup[props.row.armRight] }}
      </b-table-column>
      <b-table-column label="Legs" field="legLeft" v-slot="props">
        {{ lookup[props.row.legLeft] }} /{{ lookup[props.row.legRight] }}
      </b-table-column>
      <b-table-column label="By" field="by" v-slot="props">
        {{ props.row.by }}
      </b-table-column>
    </template>
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import obsMixin from "@/mixins/obsMixin.js";
import DatesMixin from "@/mixins/datesMixin";
import obsDataMixin from "@/mixins/obsDataMixin.js";
import LimbMovement from "@/enums/limbMovement.js";
import EnumDropDown from "@/components/EnumDropDown";

export default {
  mixins: [obsMixin, DatesMixin, obsDataMixin],
  components: { ObsDialog, EnumDropDown },
  data() {
    return {
      armSelect: LimbMovement.ArmSelect,
      legSelect: LimbMovement.LegSelect,
      lookup: LimbMovement.LookupShort
    };
  },
  watch: {
    // copy left values to right if not set
    "observation.value": {
      handler(v) {
        if (!this.observation.armRight) this.observation.armRight = v;
      }
    },
    "observation.legLeft": {
      handler(v) {
        if (!this.observation.legRight) this.observation.legRight = v;
      }
    }
  },
  methods: {
    getClearFormObject() {
      return {
        observationId: 0,
        residentId: this.$store.getters["resident/residentId"],
        value: null,
        armRight: null,
        legLeft: null,
        legRight: null,
        notes: null
      };
    }
  }
};
</script>
