<template>
  <ObsDialog
    :icon="Icons.obsSkin"
    url="obs_skin/"
    obName="Skin Condition"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
    :addPermission="permissions.WoundAdd"
    :updatePermission="permissions.WoundUpdate"
    :hideNotes="true"
    :isWideDialog="true"
  >
    <b-field label="Full Skin Check" horizontal class="label-long">
      <b-checkbox v-model="observation.checkCompleted" required :disabled="readonly">Completed</b-checkbox>
    </b-field>
    <b-field label="New Skin Abnormalities" horizontal class="label-long">
      <YesNoPicker v-model="observation.newAbnormalities" name="abnormalities" required :disabled="readonly" />
    </b-field>

    <NotesIconField v-model="observation.notes" type="textarea" :readonly="readonly" notesRef="notes" fieldClass="label-long" />
    <RecordedBy :date="observation.obsDate" :by="observation.by" fieldClass="label-long" />

    <b-field label="Current Wounds" horizontal class="label-long">
      <ClickIcon v-if="!readonly" icon="add" @click="addWound" title="Add Wound" />
      <p />
    </b-field>
    <WoundsList
      id="skinWounds"
      :currentOnly="true"
      ref="woundList"
      :selectWoundIds="observation.woundIds"
      :disabled="readonly"
      @woundAdded="woundAdded"
      @gotNumWounds="updateExistingWounds"
      :inDialog="true"
    />
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import obsMixin from "@/mixins/obsMixin.js";
import SkinIntegrityEnum from "@/enums/skinIntegrity";
import YesNoPicker from "@/components/YesNo";
import ResidentMixin from "@/mixins/residentMixin";
import woundsMixin from "@/mixins/woundsMixin.js";
import WoundsList from "@/components/Wounds/WoundsList";
import PermissionsEnum from "@/enums/permissions";
import ClickIcon from "@/components/ClickIcon.vue";
import { mapGetters } from "vuex";
import NotesIconField from "@/components/NotesIconField.vue";
import RecordedBy from "@/components/RecordedBy.vue";

export default {
  mixins: [obsMixin, woundsMixin, ResidentMixin],
  components: { ObsDialog, YesNoPicker, WoundsList, ClickIcon, NotesIconField, RecordedBy },
  data() {
    return {
      skinIntegrity: SkinIntegrityEnum,
      permissions: PermissionsEnum
    };
  },
  computed: {
    ...mapGetters(["hasPermission"]),
    readonly() {
      // check permision
      return !!this.observation.observationId && !this.hasPermission(PermissionsEnum.WoundUpdate);
    },
    canAddNew() {
      return this.isEdit && this.hasPermission(PermissionsEnum.WoundAdd);
    }
  },
  watch: {
    "observation.newAbnormalities": {
      handler(v) {
        // launch addWound for new ob
        if (v && !this.observation.observationId) this.addWound();
      }
    }
  },
  methods: {
    addWound() {
      this.$refs.woundList.addWound();
    },
    woundAdded(woundId) {
      this.observation.woundIds.push(woundId);
      this.observation.value = this.observation.woundIds.length;
      this.observation.newAbnormalities = true;
    },
    getClearFormObject() {
      return {
        observationId: 0,
        residentId: this.$store.getters["resident/residentId"],
        value: 0,
        existingWounds: 0,
        notes: null,
        newAbnormalities: null,
        checkCompleted: false,
        woundIds: [],
        notDirty: null
      };
    },
    resetForm() {
      // reset but keep existingWounds
      const existing = this.observation.existingWounds;
      this.observation = this.getClearFormObject();
      this.updateExistingWounds(existing);
    },
    updateExistingWounds(numWounds) {
      this.observation.existingWounds = numWounds;
      this.observation.notDirty = true;
    }
  },
  mounted() {
    // can't save without the completed check box, but its not actually saved
    // so on edit we set it
    if (this.isEdit) this.observation.checkCompleted = true;
  }
};
</script>
