<template>
  <section class="section is-main-section">
    <b-tabs v-model="obsTab" position="is-centered">
      <b-tab-item label="All Obs">
        <div class="columns is-multiline is-mobile">
          <ObsWidget
            :value="fullObs.height"
            :icon="Icons.obsHeight"
            suffix="cm"
            label="Height"
            :loading="isLoadingObs"
            @edit="editHeight"
            @add="newHeight"
            @graph="graphHeight"
          />
          <ObsWidget
            :value="fullObs.weight"
            :icon="Icons.obsWeight"
            suffix="kg"
            label="Weight"
            :loading="isLoadingObs"
            @edit="editWeight"
            @add="newWeight"
            @graph="graphWeight"
          />
          <BloodWidget
            :value="fullObs.blood"
            :number2="diastolic"
            :loading="isLoadingObs"
            @edit="editBlood"
            @add="newBlood"
            @graph="graphBlood"
          />
          <ObsWidget
            :value="fullObs.oxygen"
            :icon="Icons.obsOxygen"
            suffix="%"
            label="Oxygen"
            :loading="isLoadingObs"
            @edit="editOxygen"
            @add="newOxygen"
            @graph="graphOxygen"
          />
          <PainWidget :value="fullObs.pain" :loading="isLoadingObs" @edit="editPain" @add="newPain" @graph="graphPain" />
          <ObsWidget
            :value="fullObs.heart"
            :icon="Icons.obsPulse"
            suffix="bpm"
            label="Heart Rate"
            :loading="isLoadingObs"
            @edit="editHeart"
            @add="newHeart"
            @graph="graphHeart"
          />
          <ObsWidget
            :value="fullObs.respiration"
            :customIcon="CustomIcons.obsRespiration"
            suffix="bpm"
            label="Respiration"
            :loading="isLoadingObs"
            @edit="editRespiration"
            @add="newRespiration"
            @graph="graphRespiration"
          />
          <CoughWidget :value="fullObs.cough" :loading="isLoadingObs" @edit="editCough" @add="newCough" @graph="graphCough" />
          <ObsWidget
            :value="fullObs.temperature"
            :icon="Icons.obsTemp"
            suffix="⁰C"
            label="Temperature"
            :loading="isLoadingObs"
            @edit="editTemperature"
            @add="newTemperature"
            @graph="graphTemperature"
          />
          <SkinWidget :value="fullObs.skin" :loading="isLoadingObs" @edit="editSkin" @add="newSkin" @graph="graphSkin" />
          <ObsWidget
            :value="fullObs.conscious"
            :icon="Icons.obsConscious"
            :lookup="consciousLookup"
            label="Consciousness"
            :loading="isLoadingObs"
            @edit="editConscious"
            @add="newConscious"
            @graph="graphConscious"
          />
          <UrineWidget :value="fullObs.urine" :loading="isLoadingObs" @edit="editUrine" @add="newUrine" @graph="graphUrine" />
          <BowelWidget :value="fullObs.bowel" :loading="isLoadingObs" @edit="editBowel" @add="newBowel" @graph="graphBowel" />
          <PupilsWidget :value="fullObs.pupils" :loading="isLoadingObs" @edit="editPupils" @add="newPupils" @graph="graphPupils" />
          <BloodSugarWidget
            :value="fullObs.bloodSugar"
            :loading="isLoadingObs"
            @edit="editBloodSugar"
            @add="newBloodSugar"
            @graph="graphBloodSugar"
          />
          <ComaScaleWidget
            :value="fullObs.comaScale"
            :loading="isLoadingObs"
            @edit="editComaScale"
            @add="newComaScale"
            @graph="graphComaScale"
          />
          <LimbsWidget :value="fullObs.limbs" :loading="isLoadingObs" @edit="editLimbs" @add="newLimbs" @graph="graphLimbs" />
        </div>
      </b-tab-item>
      <b-tab-item label="Obs Chart">
        <ObsFilter v-model="filterType" :dates="searchDates" @newDates="setDates" />
        <div class="columns is-multiline is-mobile">
          <ObsWidget
            :value="fullObs.respiration"
            :customIcon="CustomIcons.obsRespiration"
            suffix=""
            label="Respiration"
            :loading="isLoadingObs"
            @edit="editRespiration"
            @add="newRespiration"
            @graph="graphRespiration"
            :chartData="chartData.respiration"
            :isLoadingChartData="isLoadingChart"
          />
          <BloodWidget
            :value="fullObs.blood"
            :number2="diastolic"
            :loading="isLoadingObs"
            @edit="editBlood"
            @add="newBlood"
            @graph="graphBlood"
            :chartData="chartData.blood"
            :isLoadingChartData="isLoadingChart"
          />
          <ObsWidget
            :value="fullObs.heart"
            :icon="Icons.obsPulse"
            suffix="bpm"
            label="Heart Rate"
            :loading="isLoadingObs"
            @edit="editHeart"
            @add="newHeart"
            @graph="graphHeart"
            :chartData="chartData.heart"
            :isLoadingChartData="isLoadingChart"
          />
          <ObsWidget
            :value="fullObs.temperature"
            :icon="Icons.obsTemp"
            suffix="⁰C"
            label="Temperature"
            :loading="isLoadingObs"
            @edit="editTemperature"
            @add="newTemperature"
            @graph="graphTemperature"
            :chartData="chartData.temperature"
            :isLoadingChartData="isLoadingChart"
          />
          <ObsWidget
            :value="fullObs.oxygen"
            :icon="Icons.obsOxygen"
            suffix="%"
            label="Oxygen"
            :loading="isLoadingObs"
            @edit="editOxygen"
            @add="newOxygen"
            @graph="graphOxygen"
            :chartData="chartData.oxygen"
            :isLoadingChartData="isLoadingChart"
          />
          <UrineWidget
            :value="fullObs.urine"
            :loading="isLoadingObs"
            @edit="editUrine"
            @add="newUrine"
            @graph="graphUrine"
            :chartData="chartData.urine"
            :isLoadingChartData="isLoadingChart"
          />
        </div>
      </b-tab-item>
      <b-tab-item label="Neuro Obs">
        <ObsFilter v-model="filterType" :dates="searchDates" @newDates="setDates" />
        <div class="columns is-multiline is-mobile">
          <!-- <ComaScaleWidget
            :value="fullObs.comaScale"
            :loading="isLoadingObs"
            @edit="editComaScale"
            @add="newComaScale"
            @graph="graphComaScale"
            :chartData="chartData.comaScale"
            :isLoadingChartData="isLoadingChart"
          />
          <BloodWidget
            :value="fullObs.blood"
            :number2="diastolic"
            :loading="isLoadingObs"
            @edit="editBlood"
            @add="newBlood"
            @graph="graphBlood"
            :chartData="chartData.blood"
            :isLoadingChartData="isLoadingChart"
          />
          <ObsWidget
            :value="fullObs.heart"
            :icon="Icons.obsPulse"
            suffix="bpm"
            label="Heart Rate"
            :loading="isLoadingObs"
            @edit="editHeart"
            @add="newHeart"
            @graph="graphHeart"
            :chartData="chartData.heart"
            :isLoadingChartData="isLoadingChart"
          />
          <ObsWidget
            :value="fullObs.temperature"
            :icon="Icons.obsTemp"
            suffix="⁰C"
            label="Temperature"
            :loading="isLoadingObs"
            @edit="editTemperature"
            @add="newTemperature"
            @graph="graphTemperature"
            :chartData="chartData.temperature"
            :isLoadingChartData="isLoadingChart"
          />
          <PupilsWidget
            :value="fullObs.pupils"
            :loading="isLoadingObs"
            @edit="editPupils"
            @add="newPupils"
            @graph="graphPupils"
            :chartData="chartData.pupils"
            :isLoadingChartData="isLoadingChart"
          />
          <BloodSugarWidget
            :value="fullObs.bloodSugar"
            :loading="isLoadingObs"
            @edit="editBloodSugar"
            @add="newBloodSugar"
            @graph="graphBloodSugar"
            :chartData="chartData.bloodSugar"
            :isLoadingChartData="isLoadingChart"
          /> -->
          <ObsChart
            :obsType="obsTypeEnum.ComaScale"
            :fromDate="search.fromDate"
            :toDate="search.toDate"
            @graph="graphComaScale"
            :chartData="chartData.comaScale"
            :isLoadingChartData="isLoadingChart"
            :customIcon="CustomIcons.obsComaScale"
          />
          <ObsChart
            :obsType="obsTypeEnum.BloodPressure"
            :fromDate="search.fromDate"
            :toDate="search.toDate"
            @graph="graphBlood"
            :chartData="chartData.blood"
            :isLoadingChartData="isLoadingChart"
            :customIcon="CustomIcons.obsBlood"
          />
          <ObsChart
            :obsType="obsTypeEnum.Pulse"
            :fromDate="search.fromDate"
            :toDate="search.toDate"
            @graph="graphHeart"
            :chartData="chartData.heart"
            :isLoadingChartData="isLoadingChart"
            :icon="Icons.obsPulse"
          />
          <ObsChart
            :obsType="obsTypeEnum.Temperature"
            :fromDate="search.fromDate"
            :toDate="search.toDate"
            @graph="graphTemperature"
            :chartData="chartData.temperature"
            :isLoadingChartData="isLoadingChart"
            :icon="Icons.obsTemp"
          />
          <ObsChart
            :obsType="obsTypeEnum.Pupils"
            :fromDate="search.fromDate"
            :toDate="search.toDate"
            @graph="graphPupils"
            :chartData="chartData.pupils"
            :isLoadingChartData="isLoadingChart"
            :icon="Icons.obsPupils"
          />
          <ObsChart
            :obsType="obsTypeEnum.BloodSugar"
            :fromDate="search.fromDate"
            :toDate="search.toDate"
            @graph="graphBloodSugar"
            :chartData="chartData.bloodSugar"
            :isLoadingChartData="isLoadingChart"
            :customIcon="CustomIcons.obsBloodSugar"
          />
          <ObsChart
            :obsType="obsTypeEnum.Limbs"
            :fromDate="search.fromDate"
            :toDate="search.toDate"
            :lookup="limbLookup"
            @graph="graphLimbs"
            :chartData="chartData.limbs"
            :isLoadingChartData="isLoadingChart"
            :icon="Icons.obsLimbs"
          />
        </div>
      </b-tab-item>
      <b-tab-item label="Neuro Obs - Stacked">
        <ObsFilter v-model="filterType" :dates="searchDates" @newDates="setDates" />
        <ObsChart
          :obsType="obsTypeEnum.ComaScale"
          :fromDate="search.fromDate"
          :toDate="search.toDate"
          @graph="graphComaScale"
          :chartData="chartData.comaScale"
          :isLoadingChartData="isLoadingChart"
          :stacked="true"
          :customIcon="CustomIcons.obsComaScale"
        />
        <ObsChart
          :obsType="obsTypeEnum.BloodPressure"
          :fromDate="search.fromDate"
          :toDate="search.toDate"
          @graph="graphBlood"
          :chartData="chartData.blood"
          :isLoadingChartData="isLoadingChart"
          :stacked="true"
          :customIcon="CustomIcons.obsBlood"
          :hidePreviousXAxis="true"
        />
        <ObsChart
          :obsType="obsTypeEnum.Pulse"
          :fromDate="search.fromDate"
          :toDate="search.toDate"
          @graph="graphHeart"
          :chartData="chartData.heart"
          :isLoadingChartData="isLoadingChart"
          :stacked="true"
          :icon="Icons.obsPulse"
          :hidePreviousXAxis="true"
        />
        <ObsChart
          :obsType="obsTypeEnum.Temperature"
          :fromDate="search.fromDate"
          :toDate="search.toDate"
          @graph="graphTemperature"
          :chartData="chartData.temperature"
          :isLoadingChartData="isLoadingChart"
          :stacked="true"
          :icon="Icons.obsTemp"
          :hidePreviousXAxis="true"
        />
        <ObsChart
          :obsType="obsTypeEnum.Pupils"
          :fromDate="search.fromDate"
          :toDate="search.toDate"
          @graph="graphPupils"
          :chartData="chartData.pupils"
          :isLoadingChartData="isLoadingChart"
          :stacked="true"
          :icon="Icons.obsPupils"
          :hidePreviousXAxis="true"
        />
        <ObsChart
          :obsType="obsTypeEnum.BloodSugar"
          :fromDate="search.fromDate"
          :toDate="search.toDate"
          @graph="graphBloodSugar"
          :chartData="chartData.bloodSugar"
          :isLoadingChartData="isLoadingChart"
          :stacked="true"
          :customIcon="CustomIcons.obsBloodSugar"
          :hidePreviousXAxis="true"
        />
        <ObsChart
          :obsType="obsTypeEnum.Limbs"
          :fromDate="search.fromDate"
          :toDate="search.toDate"
          @graph="graphLimbs"
          :chartData="chartData.limbs"
          :isLoadingChartData="isLoadingChart"
          :icon="Icons.obsLimbs"
          :stacked="true"
          :hidePreviousXAxis="true"
          :lookup="limbLookup"
        />
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import ResidentMixin from "@/mixins/residentMixin";
import ObsWidget from "@/components/observations/ObsWidget";
import HeightDialog from "@/components/observations/HeightDialog";
import WeightDialog from "@/components/observations/WeightDialog";
import SkinObsDialog from "@/components/observations/SkinObsDialog";
import SkinWidget from "@/components/observations/SkinWidget";
import OxygenDialog from "@/components/observations/OxygenDialog";
import RespirationDialog from "@/components/observations/RespirationDialog";
import HeartDialog from "@/components/observations/HeartDialog";
import BloodDialog from "@/components/observations/BloodDialog";
import BloodWidget from "@/components/observations/BloodWidget";
import TemperatureDialog from "@/components/observations/TemperatureDialog";
import ConsciousDialog from "@/components/observations/ConsciousDialog";
import PainDialog from "@/components/observations/PainDialog";
import PainWidget from "@/components/observations/PainWidget";
import UrineDialog from "@/components/observations/UrineDialog";
import UrineWidget from "@/components/observations/UrineWidget";
import PupilsDialog from "@/components/observations/PupilsDialog";
import PupilsWidget from "@/components/observations/PupilsWidget";
import BowelDialog from "@/components/observations/BowelDialog";
import BowelWidget from "@/components/observations/BowelWidget";
import BloodSugarDialog from "@/components/observations/BloodSugarDialog";
import BloodSugarWidget from "@/components/observations/BloodSugarWidget";
import CoughDialog from "@/components/observations/CoughDialog";
import CoughWidget from "@/components/observations/CoughWidget";
import ComaScaleDialog from "@/components/observations/ComaScaleDialog";
import ComaScaleWidget from "@/components/observations/ComaScaleWidget";
import LimbsDialog from "@/components/observations/LimbsDialog";
import LimbsWidget from "@/components/observations/LimbsWidget";
import ObsFilter from "@/components/observations/ObsFilter";
import ObsChart from "@/components/observations/ObsChart";

import IconsMixin from "@/mixins/iconsMixin.js";
import ConsciousLevelEnum from "@/enums/consciousLevel";

import * as chartConfig from "@/components/Charts/chart.config";
import obsDataMixin from "@/mixins/obsDataMixin.js";
import ObsType from "@/enums/observationType.js";
import FilterType from "@/enums/dateFilter.js";
import _ from "lodash";
import LimbMovement from "@/enums/limbMovement.js";

export default {
  mixins: [IconsMixin, ResidentMixin, obsDataMixin],
  components: {
    ObsWidget,
    UrineWidget,
    BloodWidget,
    PupilsWidget,
    PainWidget,
    BowelWidget,
    BloodSugarWidget,
    CoughWidget,
    ComaScaleWidget,
    SkinWidget,
    LimbsWidget,
    ObsFilter,
    ObsChart
  },
  data() {
    return {
      isLoadingObs: false,
      isLoadingChart: false,
      fullObs: {
        skin: {}
      },
      obsTypeEnum: ObsType.Enum,
      consciousLookup: ConsciousLevelEnum.LookupShort,
      obsTab: 0,
      historical: null,
      chartData: {
        respiration: null,
        blood: null,
        heart: null,
        temperature: null,
        oxygen: null,
        urine: null,
        pupils: null,
        bowel: null,
        comaScale: null
      },
      search: {
        fromDate: null,
        toDate: null,
        residentId: null,
        numPages: 0
      },
      searchDates: [null, null],
      filterType: FilterType.Enum.Last24Hours,
      debounceGetHistoricalData: _.debounce(this.getHistoricalData, 300),
      limbLookup: LimbMovement.LookupShort
    };
  },
  watch: {
    residentId: "refreshObs",
    obsTab(t) {
      if (t > 0 && !this.historical) {
        this.getHistoricalData();
      }
    }
  },
  computed: {
    diastolic() {
      return this.fullObs.blood?.diastolic ?? null;
    }
  },
  methods: {
    setDates(v) {
      this.searchDates = v;
      this.search.fromDate = v[0];
      this.search.toDate = v[1];
      this.debounceGetHistoricalData();
    },
    refreshObs() {
      if (!this.residentId) return;
      this.isLoadingObs = true;
      this.$http
        .get("observations?residentId=" + this.residentId)
        .then(r => (this.fullObs = r?.data))
        .then(() => {
          if (this.obsTab > 0) this.getHistoricalData();
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Observations"))
        .finally(() => (this.isLoadingObs = false));
    },
    getHistoricalData() {
      if (!this.residentId) return;
      this.search.residentId = this.residentId;
      this.isLoadingChart = true;

      this.$http
        .get("observations/historical/", { params: this.search })
        .then(r => (this.historical = r.data))
        .then(this.getGraphData)
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Historical Data"))
        .finally(() => (this.isLoadingChart = false));
    },
    getGraphData() {
      for (const [key, value] of Object.entries(this.historical)) {
        const chartData = value?.slice(0).reverse();
        if (chartData) {
          // prettier-ignore
          this.chartData[key] = this.customChartData[key]
            ? this.customChartData[key](chartData)
            : {
              labels: chartData.map(a => a.obsDate),
              datasets: [
                {
                  ...chartConfig.defaultDataSetOptions,
                  data: chartData.map(a => a.value),
                  label: ObsType.Lookup[ObsType.Property[key]]
                }
              ]
            };
        }
      }
    },
    showObs(component, props) {
      this.$buefy.modal.open({
        parent: this,
        component: component,
        hasModalCard: true,
        props: props,
        events: { refresh: this.refreshObs }
      });
    },
    editHeight() {
      this.showObs(HeightDialog, { passedInOb: this.fullObs.height });
    },
    newHeight() {
      this.showObs(HeightDialog);
    },
    graphHeight() {
      this.showObs(HeightDialog, { graphOnly: true });
    },
    editWeight() {
      this.showObs(WeightDialog, { passedInOb: this.fullObs.weight });
    },
    newWeight() {
      this.showObs(WeightDialog);
    },
    graphWeight() {
      this.showObs(WeightDialog, { graphOnly: true });
    },
    editSkin() {
      this.showObs(SkinObsDialog, { passedInOb: this.fullObs.skin });
    },
    newSkin() {
      this.showObs(SkinObsDialog);
    },
    graphSkin() {
      this.showObs(SkinObsDialog, { graphOnly: true });
    },
    editOxygen() {
      this.showObs(OxygenDialog, { passedInOb: this.fullObs.oxygen });
    },
    newOxygen() {
      this.showObs(OxygenDialog, { passedInOb: { delivery: 0 } });
    },
    graphOxygen() {
      this.showObs(OxygenDialog, { graphOnly: true });
    },
    editRespiration() {
      this.showObs(RespirationDialog, { passedInOb: this.fullObs.respiration });
    },
    newRespiration() {
      this.showObs(RespirationDialog);
    },
    graphRespiration() {
      this.showObs(RespirationDialog, { graphOnly: true });
    },
    editHeart() {
      this.showObs(HeartDialog, { passedInOb: this.fullObs.heart });
    },
    newHeart() {
      this.showObs(HeartDialog);
    },
    graphHeart() {
      this.showObs(HeartDialog, { graphOnly: true });
    },
    editBlood() {
      this.showObs(BloodDialog, { passedInOb: this.fullObs.blood });
    },
    newBlood() {
      this.showObs(BloodDialog);
    },
    graphBlood() {
      this.showObs(BloodDialog, { graphOnly: true });
    },
    editTemperature() {
      this.showObs(TemperatureDialog, { passedInOb: this.fullObs.temperature });
    },
    newTemperature() {
      this.showObs(TemperatureDialog);
    },
    graphTemperature() {
      this.showObs(TemperatureDialog, { graphOnly: true });
    },
    editConscious() {
      this.showObs(ConsciousDialog, { passedInOb: this.fullObs.conscious });
    },
    newConscious() {
      this.showObs(ConsciousDialog);
    },
    graphConscious() {
      this.showObs(ConsciousDialog, { graphOnly: true });
    },
    editPain() {
      this.showObs(PainDialog, { passedInOb: this.fullObs.pain });
    },
    newPain() {
      this.showObs(PainDialog);
    },
    graphPain() {
      this.showObs(PainDialog, { graphOnly: true });
    },
    editUrine() {
      this.showObs(UrineDialog, { passedInOb: this.fullObs.urine });
    },
    newUrine() {
      this.showObs(UrineDialog);
    },
    graphUrine() {
      this.showObs(UrineDialog, { graphOnly: true });
    },
    editPupils() {
      this.showObs(PupilsDialog, { passedInOb: this.fullObs.pupils });
    },
    newPupils() {
      this.showObs(PupilsDialog);
    },
    graphPupils() {
      this.showObs(PupilsDialog, { graphOnly: true });
    },
    editBowel() {
      this.showObs(BowelDialog, { passedInOb: this.fullObs.bowel });
    },
    newBowel() {
      this.showObs(BowelDialog);
    },
    graphBowel() {
      this.showObs(BowelDialog, { graphOnly: true });
    },
    editBloodSugar() {
      this.showObs(BloodSugarDialog, { passedInOb: this.fullObs.bloodSugar });
    },
    newBloodSugar() {
      this.showObs(BloodSugarDialog);
    },
    graphBloodSugar() {
      this.showObs(BloodSugarDialog, { graphOnly: true });
    },
    editCough() {
      this.showObs(CoughDialog, { passedInOb: this.fullObs.cough });
    },
    newCough() {
      this.showObs(CoughDialog);
    },
    graphCough() {
      this.showObs(CoughDialog, { graphOnly: true });
    },
    editComaScale() {
      this.showObs(ComaScaleDialog, { passedInOb: this.fullObs.comaScale });
    },
    newComaScale() {
      this.showObs(ComaScaleDialog);
    },
    graphComaScale() {
      this.showObs(ComaScaleDialog, { graphOnly: true });
    },
    editLimbs() {
      this.showObs(LimbsDialog, { passedInOb: this.fullObs.limbs });
    },
    newLimbs() {
      this.showObs(LimbsDialog);
    },
    graphLimbs() {
      this.showObs(LimbsDialog, { graphOnly: true });
    }
  }
};
</script>
