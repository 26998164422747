<template>
  <ObsDialog
    class="wideTabletDialog"
    :customIcon="CustomIcons.obsPainInvert"
    url="obs_pain/"
    obName="Pain Score"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    :customChartData="customChartData"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
    :hideNotes="true"
  >
    <b-field label="Before Medication" horizontal>
      <PainChart v-model="observation.value" :readonly="readonly || partialReadonly" mapRef="before" />
    </b-field>

    <NotesIconField v-model="observation.notes" type="textarea" :readonly="readonly || partialReadonly" notesRef="beforeNotes" />
    <RecordedBy :date="observation.obsDate" :by="observation.by" />

    <b-field label="Enter value after Medication" horizontal class="label-long">
      <b-checkbox v-model="observation.onMeds" :disabled="readonly" />
    </b-field>
    <b-field label="After Medication" horizontal v-if="observation.onMeds">
      <PainChart v-model="observation.after" :readonly="readonly" mapRef="after" />
    </b-field>
    <NotesIconField v-if="observation.onMeds" v-model="observation.notesAfter" type="textarea" :readonly="readonly" notesRef="afterNotes" />
    <RecordedBy :date="observation.obsDateAfter" :by="observation.byAfter" />

    <template v-slot:tableColumns>
      <b-table-column label="Date" field="obsDate" v-slot="props">
        {{ shortFormatDateTime(props.row.obsDate) }}
      </b-table-column>
      <b-table-column label="Before Meds" field="value" v-slot="props" cellClass="has-text-centered">
        {{ props.row.value }}
      </b-table-column>
      <b-table-column label="After Meds" field="value" v-slot="props" cellClass="has-text-centered">
        {{ props.row.after }}
      </b-table-column>
      <b-table-column label="By" field="by" v-slot="props">
        {{ props.row.by }}
      </b-table-column>
    </template>
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import PainChart from "@/components/observations/PainChart.vue";
import NotesIconField from "@/components/NotesIconField.vue";
import RecordedBy from "@/components/RecordedBy.vue";
import obsMixin from "@/mixins/obsMixin.js";
import DatesMixin from "@/mixins/datesMixin";
import * as chartConfig from "@/components/Charts/chart.config";
import PermissionsEnum from "@/enums/permissions";

export default {
  mixins: [obsMixin, DatesMixin],
  components: { ObsDialog, PainChart, NotesIconField, RecordedBy },
  data() {
    return {
      initialAfterValue: this.passedInOb?.after
    };
  },
  watch: {
    "observation.onMeds": {
      handler(v) {
        if (v && this.observation.after === null) this.observation.after = 0;
        if (!v) this.observation.after = null;
      }
    }
  },
  computed: {
    partialReadonly() {
      // check permision
      return !!this.observation.observationId && !this.hasPermission(PermissionsEnum.ObservationUpdate);
    },
    readonly() {
      // if we hvae observationId and not ObservationUpdate
      // but if we ObservationAdd, and not After value, we can still update that
      return (
        !!this.observation.observationId &&
        !(
          this.hasPermission(PermissionsEnum.ObservationUpdate) ||
          (this.hasPermission(PermissionsEnum.ObservationAdd) && this.initialAfterValue === null)
        )
      );
    }
  },
  methods: {
    getClearFormObject() {
      return {
        observationId: 0,
        residentId: this.$store.getters["resident/residentId"],
        value: 0,
        after: null,
        onMeds: false,
        notes: null
      };
    },
    customChartData(chartData) {
      // This was code to ty display 2 sets of time data in one scale, using the linear scale rather than time scale
      // For the labels, we need date values to be rounded to the minute
      // var labelsCombined = _.union(
      //   chartData.map(a => a.obsDate),
      //   chartData.map(a => a.obsDateAfter)
      // );
      // var labels = chartData.map(a => a.obsDate);
      // var labelsAfter = chartData.map(a => a.obsDateAfter);
      // labelsCombined = labelsCombined.filter(item => item !== null).sort();

      // use time scale, dates set as x point data
      return {
        datasets: [
          {
            ...chartConfig.defaultDataSetOptions,
            data: chartData.map(a => ({ x: a.obsDate, y: a.value })),
            label: "Before Meds"
          },
          {
            ...chartConfig.defaultDataSetOptions,
            data: chartData.filter(d => d.obsDateAfter !== null).map(a => ({ x: a.obsDateAfter, y: a.after })),
            label: "After Meds",
            borderColor: chartConfig.chartColors.default.complianceFull,
            pointBackgroundColor: chartConfig.chartColors.default.complianceFull,
            pointHoverBackgroundColor: chartConfig.chartColors.default.complianceFull
          }
        ]
      };
    }
  }
};
</script>

<style>
@media screen and (min-width: 769px) and (max-width: 1250px) {
  .wideTabletDialog .modal-card {
    width: 60vw;
  }
}
</style>
