<template>
  <ObsWidget
    :value="value"
    :number2="existingWounds"
    :showNum2="true"
    :icon="Icons.obsSkin"
    label="Skin Condition"
    :loading="loading"
    @edit="$emit('edit')"
    @add="$emit('add')"
    @graph="$emit('graph')"
    :chartData="chartData"
    :loadingChart="isLoadingChartData"
    :addPermission="permissions.WoundAdd"
    subFooter="Wounds (New / Existing)"
  />
</template>

<script>
import ObsWidget from "@/components/observations/ObsWidget";
import IconsMixin from "@/mixins/iconsMixin.js";
import PermissionsEnum from "@/enums/permissions";

export default {
  mixins: [IconsMixin],
  components: { ObsWidget },
  props: ["value", "loading", "chartData", "isLoadingChartData"],
  data() {
    return {
      permissions: PermissionsEnum
    };
  },
  computed: {
    existingWounds() {
      return this.value?.existingWounds ?? null;
    }
  }
};
</script>
