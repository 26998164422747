import { GetSelectLookup } from "./common";

const Motor = {
  None: 1,
  Extension: 2,
  Flexion: 3,
  Withdraws: 4,
  Localise: 5,
  Obey: 6
};

const MotorLookup = ["", "None", "Extension to Pain", "Flexion to Pain", "Withdraws from Pain", "Localised Pain", "Obeys Commands"];

const Verbal = {
  None: 1,
  Incomprehensible: 2,
  Inappropriate: 3,
  Confused: 4,
  Orientated: 5
};

const VerbalLookup = ["", "None", "Incomprehensible Sounds", "Inappropriate Words", "Confused", "Orientated"];

const Eyes = {
  None: 1,
  Pain: 2,
  Speech: 3,
  Spontaneously: 4
};

const EyesLookup = ["", "None", "To Pain", "To Speech", "Spontaneously"];

export default {
  Motor: Motor,
  MotorSelectLookup: GetSelectLookup(Motor, MotorLookup),
  MotorLookup: MotorLookup,
  Verbal: Verbal,
  VerbalSelectLookup: GetSelectLookup(Verbal, VerbalLookup),
  VerbalLookup: VerbalLookup,
  Eyes: Eyes,
  EyesSelectLookup: GetSelectLookup(Eyes, EyesLookup),
  EyesLookup: EyesLookup,
  ScoreRating: { Severe: 0, Moderate: 1, Mild: 2 },
  ScoreLookup: ["Severe", "Moderate", "Mild"]
};
