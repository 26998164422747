<template>
  <ObsDialog
    :customIcon="CustomIcons.obsCoughInvert"
    url="obs_cough/"
    obName="Cough"
    :lookup="coughEnum.Lookup"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
  >
    <b-field label="Cough" horizontal>
      <EnumDropDown v-model="observation.value" :data="coughEnum.SelectLookup" required :disabled="readonly" v-focus />
    </b-field>
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import obsMixin from "@/mixins/obsMixin.js";
import EnumDropDown from "@/components/EnumDropDown";
import CoughEnum from "@/enums/cough.js";

export default {
  mixins: [obsMixin],
  components: { ObsDialog, EnumDropDown },
  data() {
    return {
      coughEnum: CoughEnum
    };
  }
};
</script>
