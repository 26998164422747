<template>
  <ObsDialog
    :icon="Icons.obsPupils"
    url="obs_pupils/"
    obName="Pupils"
    obNames="Pupils"
    unit="mm"
    :observation="observation"
    :readonly="readonly"
    :canAddNew="canAddNew"
    :graphOnly="graphOnly"
    :customChartData="customChartData.pupils"
    :customChartOptions="customChartOptions.pupils"
    @close="$emit('close')"
    @refresh="v => $emit('refresh', v)"
    @select="v => (this.observation = v)"
    @clear="resetForm"
    :loadOnOpen="loadOnOpen"
    @mapServerData="mapServerData"
  >
    <div class="columns">
      <div class="column">
        <p class="has-text-centered title is-2">Left Eye</p>
        <b-field label="Pupil Scale" horizontal class="label-long">
          <div class="levelLeftMobile">
            <b-numberinput
              expanded
              v-model="observation.value"
              :controls="false"
              required="!readonly"
              :disabled="readonly"
              v-focus
              min="1"
              max="8"
            />
            <p class="control unitLabel">mm</p>
          </div>
        </b-field>
        <b-field label="Reaction" horizontal class="label-long">
          <PupilReactPicker v-model="observation.leftReaction" name="leftReaction" />
        </b-field>
      </div>
      <div class="column">
        <p class="has-text-centered title is-2">Right Eye</p>
        <b-field horizontal class="label-long">
          <div class="levelLeftMobile">
            <b-numberinput
              expanded
              v-model="observation.rightSize"
              :controls="false"
              required="!readonly"
              :disabled="readonly"
              min="1"
              max="8"
            />
            <p class="control unitLabel">mm</p>
          </div>
        </b-field>
        <b-field horizontal class="label-long">
          <PupilReactPicker v-model="observation.rightReaction" name="rightReaction" />
        </b-field>
      </div>
    </div>

    <template v-slot:tableColumns>
      <b-table-column label="Date" field="obsDate" v-slot="props">
        {{ formatDateTimeRecent(props.row.obsDate) }}
      </b-table-column>
      <b-table-column label="Left Eye" field="value" v-slot="props"> {{ props.row.value }} mm </b-table-column>
      <b-table-column label="Reaction" field="value" v-slot="props">
        {{ lookup[props.row.leftReaction] }}
      </b-table-column>
      <b-table-column label="Right Eye" field="value" v-slot="props"> {{ props.row.rightSize }} mm </b-table-column>
      <b-table-column label="Reaction" field="value" v-slot="props">
        {{ lookup[props.row.rightReaction] }}
      </b-table-column>
      <b-table-column label="By" field="by" v-slot="props">
        {{ props.row.by }}
      </b-table-column>
    </template>
  </ObsDialog>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import PupilReactPicker from "@/components/observations/PupilReactPicker.vue";
import PupilReaction from "@/enums/pupilReaction.js";
import obsMixin from "@/mixins/obsMixin.js";
import DatesMixin from "@/mixins/datesMixin";
import obsDataMixin from "@/mixins/obsDataMixin.js";

export default {
  mixins: [obsMixin, DatesMixin, obsDataMixin],
  components: { ObsDialog, PupilReactPicker },
  data() {
    return {
      lookup: PupilReaction.Lookup
    };
  },
  methods: {
    getClearFormObject() {
      return {
        observationId: 0,
        residentId: this.$store.getters["resident/residentId"],
        value: null,
        rightSize: null,
        leftReaction: null,
        rightReaction: null,
        notes: null
      };
    }
  }
};
</script>
